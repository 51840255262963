.logo {
    height: 48px;
    background: #ffffff;
    padding: 20px 16px 16px 24px;
    width: 100px;
  }
  
  .Sidebar {
    z-index: 100;
    height: 100vh;
    position: fixed;
    left: 0;
    width: 180px;
    border-right: 1px solid;
    /* border-right-color: #dddddd; */
    border-right-color: #36096D;
  }
/* a .active::before {
  padding:0;
}

.ant-menu-title-content{
  left:0;

}
li,
.ant-menu-item, ant-menu-item-selected, ant-menu-item-only-child {
  padding: 0;
}

li {
  padding: 0;
} */